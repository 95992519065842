

























































































































.messagePlaceholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  background-color: var(--v-messageBackground-base);

  > div {
    text-align: center;
  }
}

.IMBiz {
  font-size: 90px;
  letter-spacing: 4px;
  font-weight: bold;
}

.root {
  padding: 0;
  height: 100%;

  .row,
  .col,
  .col > * {
    height: 100%;
  }
}
