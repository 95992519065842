

























































































::v-deep .v-text-field {
  padding-top: 0px;
  font-size: 36px;
  margin-top: 0px;
}
::v-deep .v-input {
  flex: 0 1 auto;
}
::v-deep .v-icon.v-icon.v-icon--link {
  font-size: 36px;
}
.blocked {
  background-color: var(--v-primary-base);
  color: var(--v-primary_inverse-base);
  position: absolute;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.typer {
  justify-content: space-evenly;
  padding: 5px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #ededed;
  box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.2);

  input[type='text'] {
    left: 2.5rem;
    padding: 1rem;
    width: 85%;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.25rem;
  }
}
